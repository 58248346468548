<template>
	<!--begin::Layout-->
	<div class="row">
    <div class="col">
      <h2>General Terms and Conditions (CGV, FR)</h2>
      <vue-pdf-embed
        ref="pdfRef"
        source="/cgv.pdf"
        :page="page"
        @rendered="handleDocumentRender"
		  />
    </div>
	</div>
	<!--end::Layout-->
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed";
export default {
	components: {
		VuePdfEmbed,
	},
  data() {
    return {
      isLoading: true,
      page: null,
      pageCount: 1,
      showAllPages: true,
    }
  },
  methods: {
    handleDocumentRender() {
      this.isLoading = false;
      this.pageCount = this.$refs.pdfRef.pageCount;
    },
  },
};
</script>
